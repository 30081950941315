import LoginPage from "views/Pages/LoginPage.js";

import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import SettingsIcon from "@material-ui/icons/Settings";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";
import PeopleAltIcon from "@material-ui/icons/People";
import ChromeReaderModeIcon from "@material-ui/icons/ChromeReaderMode";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
// @material-ui/icons
// import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import DateRange from "@material-ui/icons/DateRange";
// import GridOn from "@material-ui/icons/GridOn";
import ListAltIcon from "@material-ui/icons/ListAlt";
import LockIcon from "@material-ui/icons/Lock";
import MoneyIcon from "@material-ui/icons/Money";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import SettingsApplicationsTwoToneIcon from "@material-ui/icons/SettingsApplicationsTwoTone";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import AssessmentIcon from "@material-ui/icons/Assessment";
// import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWalletIcon";
// import MenuOpenIcon from "@material-ui/icons/MenuOpen";
import Transactions from "views/Transactions/transaction";
import SearchTransaction from "views/SearchTransaction";
import DnsRoundedIcon from "@material-ui/icons/DnsRounded";
import Timelog from "views/Transactions/Timelog.js";
import ShowTransaction from "views/SearchTransaction/ShowTransaction.js";
import CreateTransaction from "views/CreateTransaction";
import Payments from "views/Payments";
// import SearchBiller from "views/SearchBiller";
import CreatePayment from "views/Payments/CreatePayment.js";
import User from "views/User";
import ShowUser from "views/User/ShowUser";
import BankSettings from "views/BankSetting";
import SettingLogs from "views/BankSetting/BankSettingLogs";
import MenuIcon from "@material-ui/icons/Menu";
import ProviderSettings from "views/ProviderSetting/providerSetting";
import Client from "views/Client";
import ShowClient from "views/Client/ShowClient";
import SubClient from "views/SubClient";
import ClientTimelog from "views/Client/ClientTimelog";
import ProviderLogs from "views/ProviderSetting/ProviderLogs";
import CurrencyConversionLogs from "views/CurrencyConversion/CurrencyConversionLogs";
import Analytics from "views/Analytics";
import BlockListRules from "views/BlocklistRules";
import RateLogs from "views/RateLogs";
import CurrencyConversion from "views/CurrencyConversion/currencyConversion";
import Setting from "views/Setting";
import DashboardSummary from "views/Dashboard/index";
import ViewBalance from "views/Balances";
import WalletBalance from "views/WalletBalance";
import ConvertUsd from "views/ConvertUsd";
import ShowFee from "views/ShowFee";
import DailyTransactionLimit from "views/DailyTransactionLimit";
import UpdatePassword from "views/UpdatePassword";
// import NibsspayDebitAccount from "views/DebitAccount/Nibsspay";
// import RubiesbankDebitAccount from "views/DebitAccount/Rubiesbank";
import VerifyAccount from "views/Pages/VerifyAccount.js";
import AmountDue from "views/AmountDue";
import CreatePaymentPayKii from "views/Payments/CreatePaymentPayKii";
import IOCatalog from "views/IoCatalog/IOCatalogDetailsScreen.js";
import ExchangeRate from "views/ExchangeCommission/ExchangeRate";
import SourceCurrencyConversion from "views/CurrencyConversion/SourceCurrencyConversion";

import SourceCurrencyConversionLogs from "views/CurrencyConversion/SourceCurrencyConversionlog";
import ExchangeCommissionLogs from "views/ExchangeCommission/ExchangeCommissionLogs";
import FiveOFive from "components/505/505";
import AddPaymentGateway from "views/PaymentGateway";
import PaymentGatewaySetting from "views/PaymentGateway/paymentGatewaySettings";
import CreateDebitAccount from "views/DebitAccount/createDebitAccount";
import VisaDirectTransaction from "views/VisaDirect";
import CreateTransactionUsd from "views/CreateTransaction/createTransactionUsd";
import AgentCreateTransaction from "views/CreateTransaction/agentTransaction";
import AgentCreateWireTransaction from "views/CreateTransaction/agentCreateWireTransaction";
import AgentCreateUsdtTransaction from "views/CreateTransaction/agentCreateUsdtTransaction";
import TransactionFee from "views/TransactionFee";
import CountryWiseSettings from "views/BankSetting/CountryWiseSetting";
import ExchangeRateView from "views/ExchangeCommission/ExchangeRateView";
import EtransferCreateTransaction from "views/CreateTransaction/EtransferCreateTransaction";
import WireCreateTransaction from "views/CreateTransaction/WireCreateTransaction"

var dashRoutes = [
  // {
  //   path: "/dashboard",
  //   name: "Dashboard",
  //   rtlName: "لوحة القيادة",
  //   icon: DashboardIcon,
  //   component: Dashboard,
  //   layout: "/admin",
  // },
  {
    path: "/auth/verify_bank_account",
    component: VerifyAccount,
    layout: "/auth",
    // name: "VerifyAccount",
    user_role: ["admin", "agent", "support", "txnagent", "wiretxnagent", "cryptoagent"],
  },
  {
    path: "/505",
    component: FiveOFive,
    layout: "/admin",
    // name: "VerifyAccount",
    user_role: ["admin", "agent", "support", "txnagent", "wiretxnagent", "cryptoagent", "superadmin"],
  },
  //enable for only super user
  {
    path: "/dashboard",
    name: "Dashboard",
    component: DashboardSummary,
    layout: "/admin",
    icon: DashboardIcon,
    user_role: ["ba"],
  },
  {
    collapse: true,
    name: "Transactions",
    state: "transactionCollapse",
    icon: AssignmentIcon,
    user_role: ["admin", "agent", "support", "txnagent", "wiretxnagent", "cryptoagent", "superadmin"],

    views: [
      {
        path: "/transactions",
        name: "Transactions",
        icon: AssignmentIcon,
        component: Transactions,
        layout: "/admin",
        user_role: ["admin", "agent", "support","superadmin"],
      },
      {
        path: "/search_transaction",
        name: "Search Transaction",
        rtlName: "لوحة القيادة",
        icon: DnsRoundedIcon,
        component: SearchTransaction,
        layout: "/admin",
        user_role: ["admin", "agent", "support","superadmin"],
      },
      {
        path: "/create_transaction",
        name: "Create Transaction",
        icon: ChromeReaderModeIcon,
        component: CreateTransaction,
        layout: "/admin",
        user_role: ["admin", "agent","superadmin"],
      },
      {
        path: "/create_agent_transaction",
        name: "Create Agent Transaction",
        icon: ChromeReaderModeIcon,
        component: AgentCreateTransaction,
        layout: "/admin",
        user_role: ["txnagent"],
      },
      // {
      //   path: "/create_agent_wire_transaction",
      //   name: "Create Agent Wire Transaction",
      //   icon: ChromeReaderModeIcon,
      //   component: AgentCreateWireTransaction,
      //   layout: "/admin",
      //   user_role: ["wiretxnagent"]
      // },
      // {
      //   path: "/usd_create_transaction",
      //   name: "Create Transaction Usd",
      //   icon: ChromeReaderModeIcon,
      //   component: CreateTransactionUsd,
      //   layout: "/admin",
      //   user_role: ["admin", "agent","superadmin"],
      // },
      // {
      //   path: "/create_visa_direct_transaction",
      //   name: "Create Visa Direct Transaction",
      //   icon: ChromeReaderModeIcon,
      //   component: VisaDirectTransaction,
      //   layout: "/admin",
      //   user_role: ["admin", "agent","superadmin"],
      // },
      {
        path: "/create_usdt_transaction",
        name: "Create USDT/USDC Transaction",
        icon: ChromeReaderModeIcon,
        component: AgentCreateUsdtTransaction,
        layout: "/admin",
        user_role: ["cryptoagent"],
      },
      // {
      //   path: "/create_etransfer_transaction",
      //   name: "Create Etransfer Transaction",
      //   icon: ChromeReaderModeIcon,
      //   component: EtransferCreateTransaction,
      //   layout: "/admin",
      //   user_role: ["admin","superadmin"],
      // },
      // {
      //   path: "/create_wire_transaction",
      //   name: "Create Wire Transaction",
      //   icon: ChromeReaderModeIcon,
      //   component: WireCreateTransaction,
      //   layout: "/admin",
      //   user_role: ["admin","superadmin"],
      // },
    ],
  },
  // {
  //   collapse: true,
  //   name: "Billers",
  //   state: "billerCollapse",
  //   icon: AssignmentTurnedInIcon,
  //   user_role: ["admin","superadmin"],

  //   views: [
  //     {
  //       path: "/biller_index",
  //       name: "Billers",
  //       icon: AssignmentTurnedInIcon,
  //       component: Payments,
  //       layout: "/admin",
  //       user_role: ["admin","superadmin"],
  //     },
  //     {
  //       path: "/search_biller",
  //       name: "Search Biller",
  //       rtlName: "لوحة القيادة",
  //       icon: DnsRoundedIcon,
  //       component: SearchBiller,
  //       layout: "/admin",
  //       user_role: ["admin","superadmin"],
  //     },
  //     {
  //       path: "/create_payment",
  //       name: "Create Payment",
  //       icon: ChromeReaderModeIcon,
  //       component: CreatePayment,
  //       layout: "/admin",
  //       user_role: ["admin","superadmin"],
  //     },
  //     {
  //       path: "/io_catalog",
  //       name: "IO Catalog",
  //       icon: ChromeReaderModeIcon,
  //       component: IOCatalog,
  //       layout: "/admin",
  //       user_role: ["admin","superadmin"],
  //     },
  //     {
  //       path: "/amount_due",
  //       name: "Amount Due",
  //       icon: ChromeReaderModeIcon,
  //       component: AmountDue,
  //       layout: "/admin",
  //       user_role: ["admin","superadmin"],
  //     },
  //     {
  //       path: "/paykii_create_payment",
  //       name: "Create Paykii Payment",
  //       icon: ChromeReaderModeIcon,
  //       component: CreatePaymentPayKii,
  //       layout: "/admin",
  //       user_role: ["admin","superadmin"],
  //     },
  //   ],
  // },
  {
    collapse: true,
    name: "Bank Setting",
    state: "banksettingCollapse",
    icon: SettingsApplicationsTwoToneIcon,
    user_role: ["admin","superadmin"],

    views: [
      {
        path: "/bank_settings",
        name: "Bank Settings",
        icon: SettingsApplicationsTwoToneIcon,
        component: BankSettings,
        layout: "/admin",
        user_role: ["admin","superadmin"],
      },
      {
        path: "/settings_logs",
        name: "Bank Settings Logs",
        icon: MenuIcon,
        component: SettingLogs,
        layout: "/admin",
        user_role: ["admin","superadmin"],
      },
      {
        path: "/coutnrywise_setting",
        name: "Country Wise Setting",
        icon: SettingsApplicationsTwoToneIcon,
        component: CountryWiseSettings,
        layout: "/admin",
        user_role: ["admin","superadmin"],
      },
    ],
  },
  {
    collapse: true,
    name: "Provider Setting",
    state: "providersettingCollapse",
    icon: DeveloperBoardIcon,
    user_role: ["admin","superadmin"],

    views: [
      {
        path: "/provider_settings",
        name: "Provider Settings",
        icon: DeveloperBoardIcon,
        component: ProviderSettings,
        layout: "/admin",
        user_role: ["admin","superadmin"],
      },
      {
        path: "/provider_logs",
        name: "Provider Settings Logs",
        icon: MenuIcon,
        component: ProviderLogs,
        layout: "/admin",
        user_role: ["admin","superadmin"],
      },
    ],
  },
  {
    path: "/transaction_fee",
    name: "Transaction Fees",
    component: TransactionFee,
    layout: "/admin",
    user_role: ["admin","superadmin"],
    icon: ListAltIcon,
  },
  {
    path: "/users",
    name: "User",
    icon: PeopleAltIcon,
    component: User,
    layout: "/admin",
    user_role: ["admin","superadmin"],
  },
  {
    path: "/client",
    name: "Client",
    icon: SupervisorAccountIcon,
    component: Client,
    layout: "/admin",
    user_role: ["admin","superadmin"],
  },
  {
    path: "/analytics",
    name: "Analytics",
    icon: AssessmentIcon,
    component: Analytics,
    layout: "/admin",
    user_role: ["admin","superadmin"],
  },
  {
    collapse: true,
    name: "Currency Conversion",
    state: "currencyCollapse",
    icon: LocalAtmIcon,
    user_role: ["admin","superadmin"],

    views: [
      {
        path: "/currency_conversions",
        name: "Currency Conversion",
        icon: LocalAtmIcon,
        component: CurrencyConversion,
        layout: "/admin",
        user_role: ["admin","superadmin"],
      },
      {
        path: "/conversion_time_logs",
        name: "Currency Conversion Logs",
        icon: MoneyIcon,
        component: CurrencyConversionLogs,
        layout: "/admin",
        user_role: ["admin","superadmin"],
      },
      {
        path: "/source_currency_conversions",
        name: "Source Currency Conversion",
        icon: LocalAtmIcon,
        component: SourceCurrencyConversion,
        layout: "/admin",
        user_role: ["admin","superadmin"],
      },
      {
        path: "/source_conversion_logs",
        name: "Source Currency Conversion Logs",
        icon: MoneyIcon,
        component: SourceCurrencyConversionLogs,
        layout: "/admin",
        user_role: ["admin","superadmin"],
      },
    ],
  },
  {
    collapse: true,
    name: "Exchage Rates",
    state: "exchangeCollapse",
    icon: LocalAtmIcon,
    user_role: ["admin","superadmin"],

    views: [
      {
        path: "/exchange_rate",
        name: "Exchange Rate",
        component: ExchangeRateView,
        layout: "/admin",
        icon: AccountBalanceWalletIcon,
        user_role: ["admin","superadmin"],
      },
      {
        path: "/exchange_commission_logs",
        name: "Exchange Commission Logs",
        icon: MoneyIcon,
        component: ExchangeCommissionLogs,
        layout: "/admin",
        user_role: ["admin","superadmin"],
      },
    ],
  },
  {
    path: "/block_list_rules",
    name: "BlockList Rules",
    component: BlockListRules,
    layout: "/admin",
    icon: LockIcon,
    user_role: ["admin","superadmin"],
  },
  {
    path: "/rate_logs",
    name: "Rate Logs",
    component: RateLogs,
    layout: "/admin",
    icon: MoneyIcon,
    user_role: ["superadmin"],
  },
  {
    path: "/settings",
    name: "Settings",
    component: Setting,
    layout: "/admin",
    icon: SettingsIcon,
    user_role: ["admin","superadmin"],
  },
  {
    path: "/view_balance",
    name: "View Balance",
    component: ViewBalance,
    layout: "/admin",
    icon: AccountBalanceIcon,
    user_role: ["admin","superadmin"],
  },
  {
    path: "/get_wallet_balance",
    name: "Wallet Balance",
    component: WalletBalance,
    layout: "/admin",
    icon: AccountBalanceWalletIcon,
    user_role: ["agent"],
  },
  {
    path: "/get_fee",
    name: "View Fee",
    component: ShowFee,
    layout: "/admin",
    icon: MoneyIcon,
    user_role: ["agent"],
  },
  {
    path: "/convert",
    name: "Convert",
    component: ConvertUsd,
    layout: "/admin",
    icon: AttachMoneyIcon,
    user_role: ["cryptoagent"],
  },
  {
    path: "/get_transaction_limit",
    name: "View Daily Transactible Limit",
    component: DailyTransactionLimit,
    layout: "/admin",
    icon: ListAltIcon,
    user_role: ["agent",],
  },
  {
    collapse: true,
    name: "Debit Accounts",
    state: "debitAccountsCollapse",
    icon: AccountBalanceWalletIcon,
    user_role: ["admin","superadmin"],

    views: [
      {
        path: "/create_debit_accounts",
        name: "Create Debit Account",
        component: CreateDebitAccount,
        layout: "/admin",
        icon: MoneyIcon,
        user_role: ["admin","superadmin"],
      }
      // {
      //   path: "/debit_accounts",
      //   name: "Nibsspay Debit Account",
      //   component: NibsspayDebitAccount,
      //   layout: "/admin",
      //   icon: AccountBalanceWalletIcon,
      //   user_role: ["admin","superadmin"],
      // },
      // {
      //   path: "/rubiesbank_accounts",
      //   name: "Rubiesbank Debit Account",
      //   component: RubiesbankDebitAccount,
      //   layout: "/admin",
      //   icon: AccountBalanceWalletIcon,
      //   user_role: ["admin","superadmin"],
      // },
    ],
  },
  {
    collapse: true,
    name: "Payment Gateway",
    state: "paymentGatewayCollapse",
    icon: AssignmentIcon,
    user_role: ["admin","superadmin"],

    views: [
      {
        path: "/add_paymentgateway",
        name: "Add Payment Gateway",
        icon: AssignmentIcon,
        component: AddPaymentGateway,
        layout: "/admin",
        user_role: ["admin","superadmin"],
      },
      {
        path: "/paymentgateway_setting",
        name: "Payment Gateway Setting",
        icon: ListAltIcon,
        component: PaymentGatewaySetting,
        layout: "/admin",
        user_role: ["admin","superadmin"],
      },
    ],
  },
  {
    path: "/change_password",
    name: "Update Password",
    component: UpdatePassword,
    layout: "/admin",
    user_role: ["agent", "admin", "support", "txnagent", "wiretxnagent", "cryptoagent" ,"superadmin"],
    icon: ListAltIcon,
  },

  {
    path: "/timelog/:id",
    component: Timelog,
    layout: "/admin",
    user_role: ["admin","superadmin"],
  },
  {
    path: "/showtransaction/:id",
    component: ShowTransaction,
    layout: "/admin",
    user_role: ["admin", "agent","superadmin"],
  },
  {
    path: "/show_user/:id",
    component: ShowUser,
    layout: "/admin",
    user_role: ["admin","superadmin"],
  },
  {
    path: "/show_client/:id",
    component: ShowClient,
    layout: "/admin",
    user_role: ["admin","superadmin"],
  },
  {
    path: "/subclient/:id",
    component: SubClient,
    layout: "/admin",
    user_role: ["admin","superadmin"],
  },
  {
    path: "/client_timelog/:id",
    component: ClientTimelog,
    layout: "/admin",
    user_role: ["admin","superadmin"],
  },
  {
    path: "/auth/login-page",
    // name: "Login Page",
    // mini: "L",
    component: LoginPage,
    layout: "/auth",
    user_role: ["admin", "agent", "support","superadmin"],
  },
];

export default dashRoutes;
