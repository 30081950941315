import React, { Component } from "react";
import ReactTable from "react-table";
import axios from "axios";
import {
  FormControl,
  InputLabel,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Datetime from "react-datetime";
import moment from "moment";
import CardText from "components/Card/CardText.js";
import cssstyles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import Progress from "../Components/Progress";
import apiUrl from "../api";

const base_url = apiUrl;

class RateLogs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      expanded: {},
      loading: true,
      error: null,
      selectedTransactions: [],
      fromDate: null,
      toDate: null,
      showTransactions: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = async (fromDate, toDate) => {
    try {
      const params = {};
      if (fromDate) params.from_date = moment(fromDate).format("YYYY-MM-DD");
      if (toDate) params.to_date = moment(toDate).format("YYYY-MM-DD");

      const response = await axios.get(`${apiUrl}/rate_logs.json`, {
        params,
        headers: {
          Authorization: `Bearer ${localStorage.getItem("session_token")}`,
        },
      });

      this.setState({ data: response.data, loading: false });
    } catch (error) {
      this.setState({ error: "Error fetching the data", loading: false });
      console.error("Error fetching the data", error);
    }
  };

  handleStartDateChange = (date) => {
    this.setState({ fromDate: date });
  };

  handleEndDateChange = (date) => {
    this.setState({ toDate: date });
  };

  onSearch = () => {
    const { fromDate, toDate } = this.state;
    this.setState({ loading: true });
    this.fetchData(fromDate, toDate);
    this.setState({ showTransactions: false });
  };

  handleCountClick = (date, currency, transactions) => {
    this.setState((prevState) => ({
      expanded: {
        ...prevState.expanded,
        [`${date}-${currency}`]: !prevState.expanded[`${date}-${currency}`],
      },
      selectedTransactions: transactions,
      showTransactions: true,
    }));
  };

  render() {
    const { classes } = this.props;
    const {
      data,
      loading,
      error,
      selectedTransactions,
      showTransactions,
    } = this.state;

    if (error) {
      return <Typography color="error">{error}</Typography>;
    }

    const tableData = {};
    const uniqueCurrencies = new Set();

    Object.entries(data).forEach(([_, rateLog]) => {
      Object.entries(rateLog).forEach(([date, currencies]) => {
        if (!tableData[date]) {
          tableData[date] = {};
        }
        Object.entries(currencies).forEach(([currency, info]) => {
          tableData[date][currency] = {
            count: info.count,
            transactions: info.transactions || [],
          };
          uniqueCurrencies.add(currency);
        });
      });
    });

    const currencyList = Array.from(uniqueCurrencies);

    return (
      <div className={this.state.loading === true ? classes.main_screen : ""}>
        <div align="center">{this.state.loading === true && <Progress />}</div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={2}>
            <Card>
              <CardBody style={{ padding: "20px 20px" }}>
                <InputLabel className={classes.label}>Start Date</InputLabel>
                <br />
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ placeholder: "Start Date" }}
                    id="start-date-picker-dialog"
                    label="Start Date"
                    dateFormat={"YYYY-MM-DD"}
                    value={this.state.fromDate}
                    onChange={this.handleStartDateChange}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={2}>
            <Card>
              <CardBody style={{ padding: "20px 20px" }}>
                <InputLabel className={classes.label}>End Date</InputLabel>
                <br />
                <FormControl fullWidth>
                  <Datetime
                    timeFormat={false}
                    inputProps={{ placeholder: "End Date" }}
                    id="end-date-picker-dialog"
                    label="End Date"
                    dateFormat={"YYYY-MM-DD"}
                    value={this.state.toDate}
                    onChange={this.handleEndDateChange}
                  />
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={2}>
            <Card>
              <CardBody style={{ padding: "23px 20px" }}>
                <FormControl fullWidth>
                  <Button color="rose" onClick={this.onSearch}>
                    Search
                  </Button>
                </FormControl>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12}>
            <Card>
              <CardHeader color="rose" text>
                <CardText color="rose">
                  <h4 className={classes.cardTitle}>Rate Api Stats</h4>
                </CardText>
              </CardHeader>
              <CardBody>
                <div style={{ marginBottom: "20px", overflow: "auto" }}>
                  {currencyList.length > 0 && (
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          {currencyList.map((currency) => (
                            <TableCell key={currency}>{currency}</TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(tableData).map(([date, currencies]) => (
                          <TableRow key={date}>
                            <TableCell>{date}</TableCell>
                            {currencyList.map((currency) => (
                              <TableCell key={currency}>
                                {currencies[currency]?.count > 0 ? (
                                  <span
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      this.handleCountClick(
                                        date,
                                        currency,
                                        currencies[currency]?.transactions
                                      );
                                    }}
                                  >
                                    {currencies[currency]?.count}
                                  </span>
                                ) : (
                                  <span>0</span>
                                )}
                              </TableCell>
                            ))}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  )}
                </div>
              </CardBody>
            </Card>
          </GridItem>
          {showTransactions && (
            <GridItem xs={12}>
              <Card>
                <CardHeader color="rose" text>
                  <CardText color="rose">
                    <h4 className={classes.cardTitle}>Rate Api Logs</h4>
                  </CardText>
                </CardHeader>
                <CardBody>
                  <ReactTable
                    data={selectedTransactions}
                    filterable
                    columns={[
                      {
                        Header: "ID",
                        accessor: "id",
                      },
                      {
                        Header: "Source Currency",
                        accessor: "source_currency",
                      },
                      {
                        Header: "Destination Currency",
                        accessor: "destination_currency",
                      },
                      {
                        Header: "Exchange Rate",
                        accessor: "rate",
                      },
                      {
                        Header: "Source Amount",
                        accessor: "source_amount",
                      },
                      {
                        Header: "Destination Amount",
                        accessor: "exchange_rate",
                      },
                      {
                        Header: "Payment Gateway",
                        accessor: "payment_gateway",
                      },
                      {
                        Header: "Created At",
                        id: "created_at",
                        accessor: (t) => {
                          return moment(t.created_at).format(
                            "YYYY-MM-DD - HH:mm:ss"
                          );
                        },
                      },
                    ]}
                    defaultPageSize={20}
                    showPaginationTop
                    showPaginationBottom={false}
                    className="-striped -highlight"
                  />
                </CardBody>
              </Card>
            </GridItem>
          )}
        </GridContainer>
      </div>
    );
  }
}

export default withStyles(cssstyles)(RateLogs);
